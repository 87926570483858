import {saveAs} from "file-saver";

export const nl = '\r\n';
export const userStopped = nl + "STOPPED by user ";

export function addOutputLine(self, line) {
    self.props.outputArray.push(line + nl);
    self.props.setOutputArray(self.props.outputArray);
}

export function addContent(self, text) {
    const maxSize = 2048;
    let newText = (self.props.content + text);
    newText = newText.substring(newText.length - maxSize);
    self.props.setContent(newText);
    const textarea = document.getElementById('content');
    if (textarea) {
        if ((textarea.scrollHeight - textarea.scrollTop) < 400) {
            textarea.scrollTop = textarea.scrollHeight;
        }
    }
}

export function addContentNewLine(self, text) {
    addContent(self, text + nl);
}

export function saveLog(self) {
    const blob = new Blob([self.props.content], {type: "text/plain;charset=utf-8"});
    saveAs(blob, self.props.file.name + ".log");
}

export function saveExtendedFile(self, outFileName) {
    const blob = new Blob(self.props.outputArray, {type: "text/plain;charset=utf-8"});
    saveAs(blob, outFileName);
}

export function isContinueProcessing(self) {
    if (self.props.isStopping) {
        addContentNewLine(self, userStopped);
        self.props.setIsProcessing(false);
        self.props.setIsStopping(false);
    }
    return self.props.isProcessing;
}



