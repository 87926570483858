
const dev = {
    // THIS SHOULD BE USED FOR DEVELOPMENT
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://jgxr7xup8c.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_gX6smokdQ",
        APP_CLIENT_ID: "6o94l5ictfal2fdjd1miqir2un",
        IDENTITY_POOL_ID: "eu-west-1:e92153f1-9b3d-4eb4-b338-b922a4ff5844"
    },

    // // This is production development for checking specific data
    // apiGateway: {
    //     REGION: "eu-west-1",
    //     URL: "https://sl0g7s3595.execute-api.eu-west-1.amazonaws.com/prod"
    // },
    // cognito: {
    //     REGION: "eu-west-1",
    //     USER_POOL_ID: "eu-west-1_dFfwFAdz7",
    //     APP_CLIENT_ID: "6iq9n1bvbtv3mt70bihtoqbt7o",
    //     IDENTITY_POOL_ID: "eu-west-1:bcb3afcd-cae9-408b-8b0d-0bfc9b78c2fb"
    // },
};

const prod = {
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://sl0g7s3595.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_dFfwFAdz7",
        APP_CLIENT_ID: "6iq9n1bvbtv3mt70bihtoqbt7o",
        IDENTITY_POOL_ID: "eu-west-1:bcb3afcd-cae9-408b-8b0d-0bfc9b78c2fb"
    },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
