/**
 * Subject ID
 * @type {string}
 */
export const subjectID = "subjectID";

/**
 * Date attribute
 * @type {string}
 */
export const dateKey = "date";


