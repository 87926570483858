import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {PageHeader} from "react-bootstrap";
import {FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import "./Home.css";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {extractSpecification} from "../libs/process-spec";
import {addContentNewLine, isContinueProcessing} from "../libs/util";

export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
        if (this.props.content === "") {
            addContentNewLine(this, 'Sample spec file below:\n#Comment line starts with #\n###### Subject cloud.002.0002 ######\n' +
                'Userid:                   cloud.002.0002@gmail.com\n' +
                'StartTime:             2019-08-01 00:00\n' +
                'EndTime:               2019-10-03 00:00\n' +
                '#Get CL usage:     true\n' +
                '#Get reservoir:    true\n' +
                'Version:                2\n' +
                'SaveFile:               "cloud.002.0002.txt"');
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        this.setState({isLoading: false});
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Data access</h1>
                <p>Managing access to data for CamAPS app</p>
            </div>
        );
    }

    getUser() {
        try {
            return ('User: ' + this.props.currentUser.attributes.email);
        } catch (e) {
            return ('');
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.props.setIsProcessing(true);

        try {
            const reader = new FileReader();
            const self = this;
            reader.onload = async function () {
                await extractSpecification(self, reader.result);
                // await processSpecification(self);
            };
            reader.readAsText(this.props.file);
        } catch (e) {
            isContinueProcessing(this);
            alert(e);
        }
    };

    handleStop = async event => {
        event.preventDefault();

        this.props.setIsStopping(true);

        try {
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    handleFileChange = event => {
        this.props.setFile(event.target.files[0]);
        this.props.setContent('');
    };

    validateForm() {
        return (this.props.file);
    }

    renderForm() {
        return (
            <div className="new-analysis">
                <PageHeader>
                    <div className="header">
                        <div className="pull-right">
                            <h4 className="text-right">
                                {this.getUser()}
                            </h4>
                        </div>
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="file">
                        <ControlLabel>Specification</ControlLabel>
                        <FormControl
                            onChange={this.handleFileChange}
                            disabled={this.props.isProcessing}
                            type="file"/>
                    </FormGroup>
                    <FormGroup controlId="content">
                        <FormControl
                            readOnly
                            type="text"
                            // placeholder='Sample spec file below:&#10;#Comment line starts with #&#10;###### Subject cloud.002.0002 ######&#10;Userid: 	cloud.002.0002@gmail.com&#10;StartTime:  	2019-08-01 00:00&#10;EndTime:  	2019-10-03 00:00&#10;Version: 	2&#10;SaveFile: 	"cloud.002.0002.txt"'
                            value={this.props.content}
                            componentClass="textarea"
                        />
                    </FormGroup>

                    {!this.props.isProcessing && <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading || this.props.isProcessing}
                        text="Run data extraction"
                        loadingText="Extracting data …"
                    />
                    }
                    {this.props.isProcessing && <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        onClick={this.handleStop}
                        isLoading={this.props.isStopping}
                        text="Stop"
                        loadingText="Stopping…"
                    />
                    }
                </form>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderForm() : this.renderLander()}
            </div>
        );
    }
}
