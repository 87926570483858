import {addContent, addContentNewLine, addOutputLine} from "./util";
import {processDataCategory, getDateTime, processDataCategoryStats, processSettings} from "./process-spec";

const modeAggressiveness = "aggressiveness";
const modeAttempting = "attempting";
const modeStats = "stats";
const modeWeight = "weight";
const modeCl_mode = "cl_mode";
// const modeExercise = "exercise";
const modeReservoir = "res";
const modePriming = "priming";
const modePump_alarm = "pump_alarm";
const modeRefill = "refill";
const modeRefillDeduced = "refillDeduced";
const modeWarm_up = "warm_up";
const modeSensor_stopped = "sensor_stopped";
const modeAlert_audio = "alert_audio";
const modeTdd = "tdd";
const modeTdd_algorithm = "tdd_algorithm";
const modeAlert_vibrate = "alert_vibrate";

function writeAggressiveness(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + obj.level.val + '\t' + (obj.duration.val / 60));
}

function writeAttempting(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + getAttemptingString(obj.cl_mode));
}

function writeAutoModeStats(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + obj.cl);
}

function writeTargetGlucose(self, obj) {
    let str = "";
    let profile = obj.personalTargetProfile.values;
    let units = obj.personalTargetProfile.units;
    let convert = 1.0;
    const ten = 10.0;
    if (units === "mg/dL") {
        convert = 0.05551;
    }
    let val;
    for (val of profile) {
        str = str + " " + Math.round(val * convert * ten)/ten;
    }
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + str);
}

function writeBodyWeight(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + obj.weight.val);
}

function writeClMode(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + getAutoModeString(obj.cl_mode));
}

function writeReservoir(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + obj.amt.val)
}

function writePriming(self, obj) {
    addOutputLine(self, getDateTime(obj.date))
}

function writeDateStamp(self, obj) {
    addOutputLine(self, getDateTime(obj.date))
}

function writePumpAlarm(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + obj.alarm_code.val)
}

function writeTdd(self, obj) {
    addOutputLine(self, getDateTime(obj.date)
        + '\t\t' + obj.tdd.val
        + '\t' + obj.extBolus.val
        + '\t' + (obj.tdd.val - obj.extBolus.val));
}

function writeTddAlgo(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t\t' + (obj.tdd ? obj.tdd.val : obj.weight.val));
}

export async function readAggressiveness(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding aggressiveness ");
    addOutputLine(self, "");
    addOutputLine(self, "Aggressiveness ********************************************");
    addOutputLine(self, "Time                        \tlevel \t duration");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          \t(%) \t (minutes)");

    await processDataCategory(userid, version, startTime, endTime, self, modeAggressiveness, writeAggressiveness);
}

export async function readClAttempting(self, userid, startTime, endTime, version, perform) {
    addContentNewLine(self, "");
    addContent(self, "Adding attempting mode");
    addOutputLine(self, "");
    addOutputLine(self, "Attempting_mode ********************************************");
    addOutputLine(self, "Time                        \tmode");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    if (perform) {
        await processDataCategory(userid, version, startTime, endTime, self, modeAttempting, writeAttempting);
    }
}

export async function readAutoModeStats(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding automode for stats");
    addOutputLine(self, "");
    addOutputLine(self, "Auto_mode_for_stats  ********************************************");
    addOutputLine(self, "Time                        \tCL_mode");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    const items = await processDataCategoryStats(userid, version, startTime, endTime, self, modeStats, writeAutoModeStats);

    addContentNewLine(self, "");
    addContent(self, "Adding summary automode for stats");
    addOutputLine(self, "");
    addOutputLine(self, "Summary_auto_mode_for_stats  ********************************************");
    addOutputLine(self, "Automode_on \tAutomode_off \tAutomode_attempting");
    addOutputLine(self, "(%)                   \t(%)                   \t(%)");
    addOutputLine(self,
        Math.round(items[0]*100) + "            \t"
        + Math.round(items[1]*100) + "                \t"
        + Math.round(items[2]*100));

    return Math.round(items[0]*100).toString().padStart(2) + "_";
}

export async function readTargetGlucose(self, userid, startTime, endTime) {
    addContentNewLine(self, "");
    addContent(self, "Adding target glucose");
    addOutputLine(self, "");
    addOutputLine(self, "Target_glucose ********************************************");
    addOutputLine(self, "Time                        \tTarget glucose ");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          \t(mmol/L) ");

    await processSettings(userid, startTime, endTime, self, writeTargetGlucose);
}

export async function readWeight(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding weight");
    addOutputLine(self, "");
    addOutputLine(self, "Weight  ********************************************");
    addOutputLine(self, "Time                        \tweight");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           (kg)");

    await processDataCategory(userid, version, startTime, endTime, self, modeWeight, writeBodyWeight);
}

export async function readAutoMode(self, userid, startTime, endTime, version, perform) {
    addContentNewLine(self, "");
    addContent(self, "Adding auto mode");
    addOutputLine(self, "");
    addOutputLine(self, "Auto_mode ********************************************");
    addOutputLine(self, "Time                        \tmode");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    if (perform) {
        await processDataCategory(userid, version, startTime, endTime, self, modeCl_mode, writeClMode);
    }
}

export async function readReservoir(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding reservoir");
    addOutputLine(self, "");
    addOutputLine(self, "Reservoir_remaining  ********************************************");
    addOutputLine(self, "Time                        \tamount");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           (U)");

    await processDataCategory(userid, version, startTime, endTime, self, modeReservoir, writeReservoir);
}

export async function readPriming(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding priming");
    addOutputLine(self, "");
    addOutputLine(self, "Priming_event ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modePriming, writePriming);
}

export async function readRefill(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding refill");
    addOutputLine(self, "");
    addOutputLine(self, "Refill_event ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeRefill, writeDateStamp);
}


export async function readRefillDeduced(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding refill deduced");
    addOutputLine(self, "");
    addOutputLine(self, "Refill_event_deduced ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeRefillDeduced, writeDateStamp);
}

export async function readPumpAlarm(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding pump alarms");
    addOutputLine(self, "");
    addOutputLine(self, "Pump alarm  ********************************************");
    addOutputLine(self, "Time                        \tcode");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modePump_alarm, writePumpAlarm);
}


export async function readSensorStarted(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding sensor inserted");
    addOutputLine(self, "");
    addOutputLine(self, "Sensor_inserted ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeWarm_up, writeDateStamp);
}


export async function readSensorStopped(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding sensor stopped");
    addOutputLine(self, "");
    addOutputLine(self, "Sensor_stopped ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeSensor_stopped, writeDateStamp);
}

export async function readAudioAlert(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding audio alerts");
    addOutputLine(self, "");
    addOutputLine(self, "Audio_alerts ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeAlert_audio, writeDateStamp);
}

export async function readVibrateAlert(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding vibrate alerts");
    addOutputLine(self, "");
    addOutputLine(self, "Vibrate_alerts ********************************************");
    addOutputLine(self, "Time                        \t");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           ");

    await processDataCategory(userid, version, startTime, endTime, self, modeAlert_vibrate, writeDateStamp);
}

export async function readTdd(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding TDD");
    addOutputLine(self, "");
    addOutputLine(self, "TDD_value ********************************************");
    addOutputLine(self, "Time                        \ttotal \tbasal \tbolus");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           (U/day) (U/day) (U/day)");

    await processDataCategory(userid, version, startTime, endTime, self, modeTdd, writeTdd);
}

export async function readTddAlgo(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding TDD algorithm");
    addOutputLine(self, "");
    addOutputLine(self, "Algorithm_TDD_value ********************************************");
    addOutputLine(self, "Time                        \tcalculated");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)           (U/day)");

    await processDataCategory(userid, version, startTime, endTime, self, modeTdd_algorithm, writeTddAlgo);
}

function getAttemptingString(attemptingStatus) {
    let result = "unknown";
    switch (attemptingStatus) {
        case 1:
            result = "starting";
            break;
        case 2:
            result = "stopping";
            break;
        case 3:
            result = "no_cgm";
            break;
        case 4:
            result = "no_pump_connectivity";
            break;
        case 5:
            result = "pump_delivery_suspended";
            break;
        case 6:
            result = "extended_bolus_not_allowed";
            break;
        case 7:
            result = "daily_total_insulin_exceeded";
            break;
        case 8:
            result = "bluetooth_turned_off";
            break;
        case 9:
            result = "unable_to_start_closed_loop_cycle";
            break;
        case 10:
        case 11:
            result = "depowered";
            break;
        case 12:
            result = "auto_mode actually off";
            break;
        case 13:
            result = "auto mode actually on";
            break;
        default:
    }
    return result;
}

function getAutoModeString(mode) {
    let value = "";
    switch (mode) {
        case 0:
            value = "off";
            break;
        case 1:
            value = "on";
            break;
        case 2:
            value = "trying";
            break;
        case 3:
            value = "stopping";
            break;
        case 4:
            value = "starting";
            break;
        default:
            break;
    }
    return value;
}