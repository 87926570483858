import {addContent, addContentNewLine, addOutputLine, isContinueProcessing, saveExtendedFile, saveLog} from "./util";
import {
    readAggressiveness,
    readAudioAlert,
    readAutoMode,
    readAutoModeStats,
    readClAttempting,
    readPriming,
    readPumpAlarm,
    readRefill,
    readRefillDeduced,
    readReservoir,
    readSensorStarted,
    readSensorStopped, readTargetGlucose,
    readTdd,
    readTddAlgo,
    readVibrateAlert,
    readWeight
} from "./process-spec-misc";
import * as globals from "./globalDef";
import {API} from "aws-amplify";

const glucoseCon = 0.05551;
const mmollUnits = "mmol/l";

const userIdHead = 'userid:';
const startTimeHead = 'starttime:';
const endTimeHead = 'endtime:';
const versionHead = 'version:';
const saveFileHead = 'savefile:';
const getAutomodeUsageHead = 'usage:';
const getReservoirHead = 'reservoir:';

const modeMeal = "meal";
const modeBolus = "bolus";
const modeInfusion = "inf";
const modeCgm = "cgm";
const modeBg = "bg";

let getAutomodeUsage = false;
let getReservoir = false;

let filePrefix = "";

export async function extractSpecification(self, specificationText) {
    // clear log etc
    self.props.setContent("");
    self.props.setSpecification([]);
    addContentNewLine(self, "Extracting specification from " + self.props.file.name);
    const arrayOfLines = specificationText.split("\n");

    let line;
    let userId = '';
    let startTime = '';
    let endTime = '';
    let version = 1;
    let outFile;

    do {
        line = extractLine(self, arrayOfLines);
        if (line !== null) {
            if (line.startsWith(userIdHead)) {
                userId = line.replace(userIdHead, "").trim();
            } else if (line.startsWith(startTimeHead)) {
                startTime = line.replace(startTimeHead, "").trim();
            } else if (line.startsWith(endTimeHead)) {
                endTime = line.replace(endTimeHead, "").trim();
            } else if (line.startsWith(versionHead)) {
                version = line.replace(versionHead, "").trim();
            } else if (line.startsWith(saveFileHead)) {
                outFile = line.replace(saveFileHead, "").replace(/"/g, "").trim();
                const spec = self.props.specification;
                spec.push({
                    [userIdHead]: userId,
                    [startTimeHead]: startTime,
                    [endTimeHead]: endTime,
                    [versionHead]: version,
                    [saveFileHead]: outFile
                });
                self.props.setSpecification(spec);
                // process specification
                await processSpecification(self);

            } else if (line.indexOf(getAutomodeUsageHead) >= 0) {
                getAutomodeUsage = line.indexOf("true") >= 0;
            } else if (line.indexOf(getReservoirHead) >= 0) {
                getReservoir = line.indexOf("true") >= 0;
            }
        }
    }
    while ((line !== null) && isContinueProcessing(self)) ;
}

export function extractLine(self, arrayOfLines) {
    let line = null;
    while ((arrayOfLines.length > 0) && (line === null)) {
        line = arrayOfLines[0].replace(/\r/g, '');
        arrayOfLines.shift();
        addContentNewLine(self, "Reading: " + line);
        line = line.trim().toLowerCase();
        if (line.length > 0) {
            if (line[0] === '#') {
                line = null;
            }
        } else {
            line = null;
        }
    }
    return line;
}

export async function processSpecification(self) {

    if (self.props.specification.length === 0) {
        self.props.setIsProcessing(false);
        self.props.setIsStopping(false);
        addContentNewLine(self, "Saving log file");
        saveLog(self);
    } else {
        const tmpSpecs = self.props.specification;
        const spec = tmpSpecs[0];
        tmpSpecs.shift();
        self.props.setSpecification(tmpSpecs);

        const userId = spec[userIdHead];
        const startTime = spec[startTimeHead];
        const endTime = spec[endTimeHead];
        const version = spec[versionHead];
        const outFile = spec[saveFileHead];

        self.props.setOutputArray([]);

        addContentNewLine(self, "");
        addContentNewLine(self, "*******************************************************************************************************");
        addContentNewLine(self, "Started processing: " + userId + " From: " + startTime + " To (inclusive): " + endTime +
            " Get CL usage: " + (getAutomodeUsage ? 'true' : 'false') +
            " Get reservoir: " + (getReservoir ? 'true' : 'false') +
            " Version: " + version + ' Output file name: "' + outFile + '"'
        );

        // create header
        createHeader(self, outFile);
        if (!isContinueProcessing(self)) {
            return;
        }

        // await readTargetGlucose(self, userId, startTime, endTime); // TODO: remove

        // read enteral boluses
        await readEnteralBolus(self, userId, startTime, endTime, version);
        readVoids(self);
        await readBolus(self, userId, startTime, endTime, version);
        await readInfusion(self, userId, startTime, endTime, version);
        await readCgm(self, userId, startTime, endTime, version);
        await readBg(self, userId, startTime, endTime, version);
        writeStartTime(self, userId, startTime);

        // miscellaneous
        await readAggressiveness(self, userId, startTime, endTime, version);
        await readAutoMode(self, userId, startTime, endTime, version, false);
        await readClAttempting(self, userId, startTime, endTime, version, false);
        await readWeight(self, userId, startTime, endTime, version);
        await readPriming(self, userId, startTime, endTime, version);
        await readRefill(self, userId, startTime, endTime, version);
        await readRefillDeduced(self, userId, startTime, endTime, version);
        await readPumpAlarm(self, userId, startTime, endTime, version);
        await readSensorStarted(self, userId, startTime, endTime, version);
        await readSensorStopped(self, userId, startTime, endTime, version);
        await readAudioAlert(self, userId, startTime, endTime, version);
        await readVibrateAlert(self, userId, startTime, endTime, version);
        await readTdd(self, userId, startTime, endTime, version);
        await readTddAlgo(self, userId, startTime, endTime, version);

        if (getAutomodeUsage) {
            filePrefix = await readAutoModeStats(self, userId, startTime, endTime, version);
        }

        if (getReservoir) {
            await readReservoir(self, userId, startTime, endTime, version);
        }

        await readTargetGlucose(self, userId, startTime, endTime);

        // end finishing
        addContentNewLine(self, "");
        addContentNewLine(self, "Saving extended file");
        saveExtendedFile(self, filePrefix + outFile);
        // processSpecification(self);
    }
}

function createHeader(self, userid) {
    addOutputLine(self, "ID: " + userid);
    addOutputLine(self, "Weight:        0.000 kg");
    addOutputLine(self, "Basal:          0.0 U/day");
    addOutputLine(self, "Basal rate in 30min steps (U/h):");
    addOutputLine(self, "    0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0");
    addOutputLine(self, "    0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0");
    addOutputLine(self, "    0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0");
    addOutputLine(self, "    0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0 0.0");
    addOutputLine(self, "");
}

function writeMeal(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t' + obj.meal.val);
}

function writeBolus(self, obj) {
    if (obj.stepBolus) {
        addOutputLine(self, getDateTime(obj.date) + '\t' + obj.stepBolus.val + '\t 0 \t R');
    } else if (obj.extBolus) {
        addOutputLine(self, getDateTime(obj.date) + '\t' + obj.extBolus.value + '\t ' + obj.extBolus.duration + '\t R');
    }
}

function writeInfusion(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t' + obj.inf.val + '\tR');
}

function writeCgm(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t' +
        (obj.cgm.u.toLowerCase() === mmollUnits ? obj.cgm.val : obj.cgm.val * glucoseCon));
}

function writeBg(self, obj) {
    addOutputLine(self, getDateTime(obj.date) + '\t' +
        (obj.bg.units.toLowerCase() === mmollUnits ? obj.bg.value : obj.bg.value * glucoseCon) +
        (obj.bg.isCalibrating ? ' c' : ''));
}

export async function processDataCategory(userid, version, startTime, endTime, self, mode, writeLine) {
    let tempResult = {};
    tempResult.LastEvaluatedKey = "";
    let firstRun = true;
    while (tempResult.LastEvaluatedKey || firstRun) {
        firstRun = false;
        tempResult = await subjectData(tempResult.LastEvaluatedKey, userid, mode, version, startTime, endTime);
        addContent(self, '.');
        let obj;
        for (obj of tempResult.Items) {
            if (!isContinueProcessing(self)) {
                return;
            }
            writeLine(self, obj);
        }
    }
}

export async function processDataCategoryStats(userid, version, startTime, endTime, self, mode, writeLine) {
    let tempResult = {};
    tempResult.LastEvaluatedKey = "";
    let firstRun = true;
    let automodeOn = 0;
    let automodeOff = 0;
    let automodeAttempting = 0;
    while (tempResult.LastEvaluatedKey || firstRun) {
        firstRun = false;
        tempResult = await subjectData(tempResult.LastEvaluatedKey, userid, mode, version, startTime, endTime);
        addContent(self, '.');
        let obj;
        for (obj of tempResult.Items) {
            if (!isContinueProcessing(self)) {
                return;
            }
            writeLine(self, obj);
            if (obj.cl === 'on') {
                automodeOn++;
            } else if (obj.cl === 'off') {
                automodeOff++;
            } else if (obj.cl === 'trying') {
                automodeAttempting++;
            }
        }
    }
    const total = automodeOn + automodeOff  + automodeAttempting;
    let items = [0,0,0];
    if (total !==0) {
        items = [automodeOn/total, automodeOff/total, automodeAttempting/total];
    }
    return items;
}

export async function processSettings(userid, startTime, endTime, self, writeLine) {
    let tempResult = {};
    tempResult.LastEvaluatedKey = "";
    let firstRun = true;
    let firstLine;
    let firstLineWritten = false;
    let previousLine;
    while (tempResult.LastEvaluatedKey || firstRun) {
        firstRun = false;
            tempResult = await subjectSettings(tempResult.LastEvaluatedKey, userid, "2010-06-01", endTime);
        addContent(self, '.');
        let obj;
        for (obj of tempResult.Items) {
            if (!isContinueProcessing(self)) {
                return;
            }
            if (obj.date < startTime) {
                firstLine = obj;
            } else if ((!firstLineWritten) && (obj.date >= startTime)) {
                if (!firstLine) {
                    firstLine = obj;
                    previousLine = obj;
                } else {
                    // this is the first line
                    firstLine.date = startTime;
                }
                writeLine(self, firstLine);
                if (JSON.stringify(obj.personalTargetProfile.values)
                    !== JSON.stringify(previousLine)) {
                    writeLine(self, obj);
                }
                firstLineWritten = true;
            } else {
                if (JSON.stringify(obj.personalTargetProfile.values)
                    !== JSON.stringify(previousLine)) {
                    writeLine(self, obj);
                }
            }
            previousLine = obj.personalTargetProfile.values;
        }
    }
    if ((!firstLineWritten) && firstLine) {
        // this is the first line
        firstLine.date = startTime;
        writeLine(self, firstLine);
    }
}

async function readEnteralBolus(self, userid, startTime, endTime, version) {
    addContent(self, "Adding enteral bolus ");
    addOutputLine(self, "");
    addOutputLine(self, "Enteral_bolus (meal) ********************************************");
    addOutputLine(self, "Time                        CHO");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (g)");

    await processDataCategory(userid, version, startTime, endTime, self, modeMeal, writeMeal);
}

function readVoids(self) {
    addOutputLine(self, "");
    addOutputLine(self, "Enteral_infusion ************************************************");
    addOutputLine(self, "Time                        Rate");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (g/h)");
    addOutputLine(self, "");
    addOutputLine(self, "Parenteral_bolus ************************************************");
    addOutputLine(self, "Time                        CHO");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (g)");
    addOutputLine(self, "");
    addOutputLine(self, "Parenteral_Infusion ************************************************");
    addOutputLine(self, "Time                        Rate");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (g/h)");
    addOutputLine(self, "");
}

function writeStartTime(self, userid, startTime) {
    addOutputLine(self, "");
    addOutputLine(self, "Start ******************************************");
    addOutputLine(self, "Time (local time)           Bolus");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (Y/N)");
    addOutputLine(self, getDateTime(startTime) + "  (" + getDateTime(startTime) + ")\tN");
    addOutputLine(self, "END");
}

async function readBolus(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding insulin bolus ");
    addOutputLine(self, "Insulin_bolus ********************************************");
    addOutputLine(self, "Time                        Bolus        Duration  Insulin");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (U)            (min)    (S|R|N)");

    await processDataCategory(userid, version, startTime, endTime, self, modeBolus, writeBolus);
}

async function readInfusion(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding insulin infusion ");
    addOutputLine(self, "");
    addOutputLine(self, "Insulin_infusion ************************************************");
    addOutputLine(self, "Time                        Rate");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)          (U/h)");

    await processDataCategory(userid, version, startTime, endTime, self, modeInfusion, writeInfusion);
}

async function readCgm(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding CGM values ");
    addOutputLine(self, "");
    addOutputLine(self, "Glucose_concentration ********************************************");
    addOutputLine(self, "Time                        conc");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)         (mmol/L)");

    await processDataCategory(userid, version, startTime, endTime, self, modeCgm, writeCgm);
}

async function readBg(self, userid, startTime, endTime, version) {
    addContentNewLine(self, "");
    addContent(self, "Adding BG values ");
    addOutputLine(self, "");
    addOutputLine(self, "Fingerstick_glucose_concentration ********************************************");
    addOutputLine(self, "Time                        conc");
    addOutputLine(self, "(dd/mm/yyyy hh:mm)         (mmol/L)");

    await processDataCategory(userid, version, startTime, endTime, self, modeBg, writeBg);
}

function subjectData(lastEvaluatedKey, userId, mode, version, startDate, endDate) {
    let myInit = {
        queryStringParameters: {
            subjectID: mode + ":" + version + ":" + userId,
            startDate: startDate,
            endDate: endDate
        }
    };
    if (lastEvaluatedKey && ("" !== lastEvaluatedKey)) {
        myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey[globals.subjectID];
        myInit.queryStringParameters.ExclusiveStartKeyDate = lastEvaluatedKey[globals.dateKey];
    }
    return API.get("data", "/data", myInit);
}

function subjectSettings(lastEvaluatedKey, userId, startDate, endDate) {
    let myInit = {
        queryStringParameters: {
            subjectID: userId,
            startDate: startDate,
            endDate: endDate
        }
    };
    if (lastEvaluatedKey && ("" !== lastEvaluatedKey)) {
        myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey[globals.subjectID];
        myInit.queryStringParameters.ExclusiveStartKeyDate = lastEvaluatedKey[globals.dateKey];
    }
    return API.get("data", "/settings", myInit);
}

export function getDateTime(dateOrig) {
    return dateOrig.substr(8, 2) + '/' +
        dateOrig.substr(5, 2) + '/' +
        dateOrig.substr(0, 4) +
        dateOrig.substr(10, 6);
}


